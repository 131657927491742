@import '~quill/dist/quill.snow.css';

.ql-container {
    font-family: Tahoma, Verdana, Segoe, sans-serif;
}

.view-only.mat-form-field-type-quill-wrapper-directive {
    .mat-form-field-flex {
        pointer-events: all !important;
    }

    .mat-form-field-underline {
        display: none;
    }

    [quill-editor-element] {
        height: auto;
        max-height: 200px;
        overflow: hidden;
    }

    span.quill-expand {
        cursor: pointer;
        background: var(--highlight);
        text-align: center;
        display: block;
        margin-top: 5px;

        mat-icon {
            color: var(--primary);
            transform: scale(1.5);
        }
    }
}

quill-editor {
    .ql-tooltip {
        left: calc(50% - 200px) !important;
        top: -30px !important;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3) !important;
    }

    .ql-toolbar {
        display: none;
        background: var(--grey);
        border: none !important;
        border-radius: 4px 4px 0 0;

        .ql-formats {
            .ql-picker-label:hover,
            .ql-picker-label.ql-active {
                color: var(--accent);

                .ql-stroke {
                    stroke: var(--accent);
                }
            }

            .ql-picker-label.ql-active {
                background: rgba(var(--black--rgb), 0.1);
            }

            .ql-picker-item {
                color: var(--grey);

                .ql-stroke {
                    stroke: var(--grey);
                }

                &:hover,
                &.ql-selected {
                    color: var(--accent);

                    .ql-stroke {
                        stroke: var(--accent);
                    }
                }
            }
        }

        button:hover,
        button.ql-active {
            .ql-stroke {
                fill: none;
                stroke: var(--accent) !important;
            }

            .ql-fill {
                fill: var(--accent) !important;
            }

            .ql-picker {
                color: var(--accent);
            }
        }

        button.ql-active {
            background: rgba(0, 0, 0, 0.1);
        }

        .ql-stroke {
            fill: none;
            stroke: var(--white);
        }

        .ql-fill {
            fill: var(--white) !important;
        }

        .ql-picker {
            color: var(--white);
        }
    }

    .ql-container {
        margin-top: -20px;
        height: 36px;
        opacity: 0;
        background: var(--white);
        border: none !important;
        box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.3);
    }

    &.floating:not(.cdk-focused) {
        .ql-container {
            margin-top: 0px;
            opacity: 1;
            background: none;
            box-shadow: none;
        }

        .ql-editor {
            overflow: hidden;
        }
    }

    &.show {
        opacity: 1;

        .ql-toolbar {
            display: block;
        }

        .ql-container {
            margin-top: auto;
            height: auto;
            opacity: 1;
            border: 1px solid #ccc;
        }
    }

    &.view-only.expanded {
        [quill-editor-element] {
            max-height: none;
        }
        span.quill-expand {
            mat-icon {
                transform: scale(1.5) rotate(180deg);
            }
        }
    }
}

.view-only .mat-form-field-type-quill-wrapper-directive {
    .mat-form-field-flex {
        pointer-events: all !important;
    }

    .mat-form-field-underline {
        display: none;
    }

    [quill-editor-element] {
        height: auto;
        max-height: 200px;
        overflow: hidden;
    }

    span.quill-expand {
        cursor: pointer;
        background: var(--highlight);
        text-align: center;
        display: block;
        margin-top: 5px;

        mat-icon {
            padding: 5px 0;
            color: var(--primary);
            transform: scale(1.5);
        }
    }
}
