@import '@angular/material/theming';
@import '/src/scss/variables.scss';

.mat-cell {
    color: var(--black) !important;
}

table.data-grid.mat-table {
    .mat-header-cell {
        color: var(--white);
    }

    .mat-sort-header-arrow {
        color: var(--primary);
    }

    .action-header {
        .mat-select-placeholder,
        .mat-select-value,
        .mat-select-arrow {
            color: var(--white);
        }
    }

    .swft-actions-column mat-checkbox {
        .mat-checkbox-inner-container {
            background: var(--white);
            border-radius: 3px;

            .mat-checkbox-frame {
                border-color: var(--primary);
                border-radius: 3px;
                overflow: hidden;
            }
        }
    }
}

mat-paginator {
    display: none;
    .mat-paginator-range-actions {
        button.mat-icon-button {
            color: var(--primary);
            &.mat-button-disabled {
                opacity: 0.5;
            }
        }
    }
}
