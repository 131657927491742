@import '@angular/material/theming';
@import '/src/scss/variables.scss';

.mat-cell {
    color: var(--black) !important;
}

table.mat-table:not(.sub-table, .data-grid) {
    width: 100%;
    overflow: auto;
    min-height: 116px;
    margin-bottom: 56px;

    &.view-only {
        [cdkdraghandle] {
            display: none;
        }
    }

    &.hscroll {
        width: 100%;
        display: block;
        position: relative;
        overflow-y: hidden;

        .swft-actions-column {
            position: sticky;
            right: 0;
        }
        th.swft-actions-column {
            background: var(--grey);
            border-bottom: solid 2px var(--primary);
        }
    }

    &.selectable {
        tr {
            &.selected {
                background: var(--highlight);
            }
        }
    }

    &.pinnable,
    &.expandable {
        tr {
            * {
                transition: none;
            }
            &.pinned,
            &.expanded {
                background: lighten($grey, 33%) !important;
            }
            &.pinned {
                box-shadow: -10px 0 0 0 var(--primary);
            }
        }

        button {
            background: var(--highlight) !important;
            color: var(--primary) !important;
            &.expanded {
                box-shadow: inset 0 0 0 2px var(--primary) !important;
                &::before {
                    content: '';
                    position: absolute;
                    display: block;
                    width: 0px;
                    height: 0px;
                    left: 23px;
                    top: 34px;
                    border: 10px solid transparent;
                    border-top: 8px solid var(--primary);
                    margin-bottom: 10px;
                }
            }
        }
    }

    &.expandable {
        tr:nth-of-type(4n + 3) {
            background: var(--lt-grey);
        }
    }

    tr {
        &.empty {
            opacity: 0;
            pointer-events: none;
        }
        &.mat-header-row {
            background: var(--grey) !important;
            color: var(--white);
            box-shadow: inset 0 -2px 0 0 var(--primary);
        }

        &:nth-of-type(even) {
            background: var(--lt-grey);
        }

        &:nth-of-type(odd) {
            background: var(--white);
        }

        &.detail-row {
            background: lighten($grey, 30%);
        }

        &.mat-no-data-row {
            text-align: center;
            td {
                padding: 12px !important;
            }
        }

        &:not(.mat-no-data-row, .detail-row) {
            td:not(.mat-column-actions) {
                line-height: 47px;
                height: 48px;
                background: inherit;
                max-width: 400px;
                min-width: 80px;
                overflow: hidden;

                & > *:not(button, mat-checkbox, .sub-table) {
                    width: auto;
                    max-width: 400px;
                    height: 48px;

                    * {
                        line-height: normal;
                        white-space: nowrap;
                        padding-top: 15px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                button {
                    margin-left: 4px;
                    background: var(--primary);
                    color: var(--white);
                }
            }
        }

        td {
            white-space: nowrap;
            text-overflow: ellipsis;
            transition: all 0.5s 0.3s linear, background 0.2s linear;
            text-align: left;
            &:first-of-type {
                padding-left: 12px !important;
            }

            &.swft-actions-column {
                padding: 0px !important;
                box-shadow: -3px 0 3px 0 rgba(0, 0, 0, 0.1);
                text-align: center;
                background: var(--highlight);
                transition: max-width 0.5s 0.5s linear, background 0.2s linear;

                mat-checkbox {
                    position: relative;
                    margin: 0 20px 0 8px;

                    &::after {
                        content: '';
                        position: absolute;
                        top: -13px;
                        right: -15px;
                        width: 1px;
                        height: 47px;
                        opacity: 0.1;
                        background: var(--black);
                    }

                    .mat-checkbox-inner-container {
                        background: var(--white);
                        border-radius: 3px;

                        .mat-checkbox-frame {
                            border-color: var(--primary);
                            border-radius: 3px;
                            overflow: hidden;
                        }
                    }
                }
            }

            &.expanded-row {
                padding-left: 5px;

                background: var(--highlight);

                table {
                    th {
                        font-weight: normal;
                        color: var(--primary);
                    }
                    tr {
                        background: none;

                        &:nth-of-type(even) {
                            background: rgba(var(--white--rgb), 0.25);
                        }
                    }
                }
            }
        }

        th {
            padding: 0 12px 0 0;
            width: 0px;
            white-space: nowrap;
            &:nth-of-type(1) {
                padding-left: 12px;
            }

            &.swft-actions-column {
                padding: 0 5px 0 0 !important;
                box-shadow: -3px 0 3px 0 rgba(0, 0, 0, 0.1);
                text-align: center;

                mat-checkbox {
                    margin-left: 10px;

                    &::after {
                        top: -17px;
                        height: 54px;
                    }
                }
            }
        }
    }

    .mat-header-cell {
        color: var(--white);
    }

    .mat-sort-header-arrow {
        color: var(--primary);
    }

    .action-header {
        .mat-select-placeholder,
        .mat-select-value,
        .mat-select-arrow {
            color: var(--white);
        }
    }

    .swft-actions-column mat-checkbox {
        position: relative;
        margin: 0 20px 0 8px;

        &::after {
            content: '';
            position: absolute;
            top: -13px;
            right: -15px;
            width: 1px;
            height: 47px;
            opacity: 0.1;
            background: var(--black);
        }

        .mat-checkbox-inner-container {
            background: var(--white);
            border-radius: 3px;

            .mat-checkbox-frame {
                border-color: var(--primary);
                border-radius: 3px;
                overflow: hidden;
            }
        }
    }

    // Prevents margin on paragraphs in tables
    .mat-typography p {
        margin: 0 !important;
    }
}

mat-paginator.mat-paginator:not(.data-grid) {
    background: var(--grey);
    color: var(--white);
    margin-bottom: 30px;
    margin-top: -56px;

    .mat-paginator-navigation-previous,
    .mat-paginator-navigation-next {
        color: var(--primary);

        &.mat-button-disabled {
            color: var(--primary);
            opacity: 0.3;
        }
    }
}

table:not(.data-grid).mat-table.sub-table {
    margin: 15px 0;

    tr.mat-header-row {
        background: lighten($grey, 40%) !important;
        box-shadow: none;
        line-height: 28px;
        height: 28px;
    }

    .mat-header-cell {
        color: var(--primary);
    }
}
