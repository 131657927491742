@import '@angular/material/theming';
@import './scss/variables.scss';
@import './scss/quill.scss';
@import './scss/forms.scss';
@import './scss/snackbar.scss';
@import './scss/tables.scss';
@import './scss/data-grid.scss';
@import './scss/cards.scss';
@import './scss/swft-tooltip.scss';
@import './scss/swft-radio-checkbox.scss';
@import './scss/hipaa.scss';

/**********************
 *  General styles
 *********************/

html,
body,
swft-root {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: var(--background);
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

p {
    white-space: pre-wrap;

    &.swft-error {
        background: rgba(var(--warn--rgb), 0.2);
        border: solid 4px var(--warn);
        padding: 20px;
    }
}

.login {
    .router-container {
        padding-top: 0 !important;
    }
}

// Prevent usless scrollbar from appearing when a mat-dialog is displayed
html.cdk-global-scrollblock {
    overflow: hidden;
}

// Remove styling of auto-filled inputs by webkit browsers
input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.swft-search-grid-input,
.swft-select-search-input {
    cursor: pointer;
    padding-bottom: 0;

    .mat-form-field-underline {
        display: none;
    }

    .mat-form-field-wrapper {
        padding: 0;
        margin-bottom: 10px;
        transition: all 0.3s ease-out;
    }

    * {
        pointer-events: none;
    }

    &:hover > .mat-form-field-wrapper {
        background: var(--highlight);
    }
}

.swft-search-grid-input:after {
    content: 'manage_search';
    display: block;
    font-family: 'Material Icons';
    font-size: 32px;
    position: absolute;
    right: 10px;
    top: 8px;
    color: var(--grey);
}

.swft-select-search-input:after {
    content: '';
    position: absolute;
    right: 4px;
    top: 16px;
    display: block;
    height: 20px;
    width: 20px;
    background-size: 20px 20px;
    background-image: url('./assets/icons/select_search.svg');
    background-repeat: no-repeat;
    filter: invert(0.4);
}

h1.swft-title {
    font-size: 32px !important;
    margin: 0 0 15px 0 !important;

    &::before {
        content: url('./assets/icons/pxr-bullet.svg');
        bottom: 15px;
        left: -25px;
        width: 20px;
        height: 20px;
    }
}

h2.swft-title {
    font-size: 24px !important;
    margin: 0 0 5px 0 !important;
}

h1,
h2 {
    color: var(--primary);
    font-weight: 600 !important;
    width: 100%;
    margin-bottom: 10px !important;

    span {
        font-weight: normal;
        opacity: 0.8;
    }

    &.swft-title {
        position: relative;
        border: none;
        font-weight: bold !important;
        text-transform: capitalize;

        &::before {
            position: absolute;
            display: block;
        }

        mat-icon {
            font-size: 140%;
            top: 10px !important;
            margin: 0 8px 0 -8px;
            position: relative;
            opacity: 0.35;
            overflow: visible;
        }

        span {
            font-weight: normal;

            small {
                opacity: 0.8;
            }
        }
    }
}

.mat-checkbox-label-before .mat-checkbox-inner-container {
    background: var(--lt-grey);
    border-radius: 5px;
}

ul.select-search {
    li span:nth-of-type(1) {
        font-size: 16px;
        font-weight: bold;
        color: var(--black);
        font-style: normal;
    }
    li span:nth-of-type(2) {
        font-size: 16px;
        color: var(--black);
        font-style: normal;
    }
}

/**********************
 *  CDK
 *********************/

.cdk-overlay-container {
    .cdk-overlay-backdrop {
        background: rgba(var(--grey--rgb), 0.6) !important;
        &.transparent-backdrop {
            background: transparent !important;
        }
    }
    .cdk-overlay-pane.mat-tooltip-panel {
        pointer-events: none;
    }
}

/**********************
 *  Login
 *********************/

.login-form-field.mat-form-field-appearance-legacy .mat-form-field-underline {
    background: var(--white);

    .mat-form-field-ripple {
        background: var(--white);
    }
}

/**********************
 *  Dialog
 *********************/
.cdk-overlay-pane {
    &.x-small {
        mat-dialog-container {
            max-height: 220px !important;
            width: 350px !important;
        }
    }
    &.small {
        mat-dialog-container {
            height: 275px !important;
            width: 400px !important;
        }
    }
    &.medium {
        mat-dialog-container {
            height: 412px !important;
            width: 600px !important;
        }
    }
    &.medium-auto {
        mat-dialog-container {
            height: auto !important;
            width: 600px !important;
        }
    }
    &.large {
        mat-dialog-container {
            height: 515px !important;
            width: 760px !important;
        }
    }
    &.x-large {
        mat-dialog-container {
            height: calc(100vh - 20px) !important;
            width: 900px !important;
        }
    }
    &.x-large-auto {
        mat-dialog-container {
            height: auto !important;
            width: 900px !important;
        }
    }
    &.list {
        mat-dialog-container {
            height: 550px !important;
        }
    }
    &.small-auto {
        mat-dialog-container {
            height: auto !important;
            width: 440px !important;
        }
    }
    &.group-permissions {
        mat-dialog-container {
            height: 600px !important;
            width: 900px !important;
        }
    }
    &.swft-popup-note {
        mat-dialog-container {
            padding: 0 !important;
            height: auto !important;
            width: auto !important;
            top: calc(100vh - 5px) !important;
            bottom: auto;
            right: 20px !important;
            border: solid 2px var(--primary);
        }
    }
    &.select-search-modal {
        mat-dialog-container {
            padding: 0 !important;
            height: 286px !important;
            overflow: hidden;
        }
    }
}

mat-dialog-container {
    background: linear-gradient(0deg, rgba(var(--lt-grey--rgb), 1) 0%, rgba(var(--white--rgb), 1) 100%) !important;
    height: 550px !important;
    width: 800px !important;
    margin-top: -15px;

    .dialog-header {
        position: sticky;
        top: -24px;
        color: var(--white);
        background: var(--primary);
        z-index: 1;
        padding: 8px 20px 8px 14px;
        margin: -24px 0 24px -24px !important;
        border-bottom: solid 2px var(--accent);
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
        width: calc(100% + 14px);

        h1 {
            border: none;
            color: var(--white);
            width: 60% !important;
            display: inline-block;
            margin: 0;
            font-weight: normal !important;
            width: auto;
            margin-bottom: 0px !important;
        }

        mat-slide-toggle {
            float: right;
            padding-top: 4px;
        }
    }

    .mat-tab-header {
        margin: -24px 0 0 -24px;
        width: calc(100% + 48px);
        position: sticky;
        top: -24px;
        z-index: 2;
    }

    .mat-dialog-title,
    [mat-dialog-title] {
        position: sticky;
        top: -24px;
        color: var(--white);
        background: var(--primary);
        z-index: 1;
        padding: 8px 34px 8px 14px;
        margin: -24px 0 24px -24px !important;
        width: 100%;
        border-bottom: solid 2px var(--accent);
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.3);
        font-weight: normal !important;
        text-transform: capitalize;

        &.tabs {
            top: -24px;
            margin-bottom: -26px !important;
            z-index: 3;
            background: none;
            pointer-events: none;
        }

        span {
            color: var(--grey);
            font-weight: normal;
            text-transform: capitalize;
            opacity: 1;
        }

        .mat-icon {
            float: right;
            font-size: 24px;
            color: var(--grey);
            line-height: 1.2;
            margin-right: -20px;
        }
    }
}
.mat-tab-group.sticky-header {
    .mat-tab-header {
        position: sticky;
        top: 13px;
        z-index: 10;
        background-color: var(--background);
    }
}

h1.swft-mat-tab-group {
    position: relative;
    color: var(--white);
    font-weight: bold;
    margin: 8px 0 -40px 20px !important;
    z-index: 1;
}

/**********************
 *  Scrollbars
 *********************/

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
::-webkit-scrollbar-track {
    background: var(--highlight);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb {
    background: rgba(var(--primary--rgb), 0.7);
    border-radius: 3px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--primary--rgb), 0.5);
}

/**********************
 *  Mat-SlideToggle
 *********************/
.mat-slide-toggle-bar {
    background-color: rgb(var(--grey--rgb), 0.5) !important;
}
.mat-slide-toggle.mat-checked {
    .mat-slide-toggle-thumb {
        background-color: var(--white) !important;
    }
    .mat-slide-toggle-bar {
        background-color: var(--accent) !important;
    }
}
.hide {
    display: none !important;
}
