@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}

$primary: #0b3363;
$dark: #15263f;
$light: #00aeef;
$grey: #85959e;
$lt-grey: #f9f9f9;
$accent: #93d500;
$highlight: #dfe6f1;
$warn: #c70000;
$caution: #ffb800;
$white: #ffffff;
$black: #000000;
$background: #b8c1c7;
$linkColor: #0000ff;

/* "Dark Mode" colors
// $primary: #7e7e7e;
// $dark: #757575;
// $light: #00aeef;
// $grey: #666666;
// $lt-grey: #333333;
// $accent: #93d500;
// $highlight: #484d57;
// $warn: #c70000;
// $caution: #FFB800;
// $white: #222222;
// $black: #ffffff;
// $background: #000000;*/

:root {
    --primary: #{$primary};
    --primary--rgb: #{hexToRGB($primary)};
    --dark: #{$dark};
    --dark--rgb: #{hexToRGB($dark)};
    --light: #{$light};
    --grey: #{$grey};
    --grey--rgb: #{hexToRGB($grey)};
    --lt-grey: #{$lt-grey};
    --lt-grey--rgb: #{hexToRGB($lt-grey)};
    --accent: #{$accent};
    --accent--rgb: #{hexToRGB($accent)};
    --highlight: #{$highlight};
    --highlight--rgb: #{hexToRGB($highlight)};
    --warn: #{$warn};
    --warn--rgb: #{hexToRGB($warn)};
    --caution: #{$caution};
    --caution--rgb: #{hexToRGB($caution)};
    --white: #{$white};
    --white--rgb: #{hexToRGB($white)};
    --black: #{$black};
    --black--rgb: #{hexToRGB($black)};
    --background: #{$background};
    --background--rgb: #{hexToRGB($background)};
    --link-color: #{$linkColor};
    --link-color--rgb: #{hexToRGB($linkColor)};
}
