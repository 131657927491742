.hipaa-dialog.cdk-overlay-pane {
    width: 320px;
    height: auto;

    &.wide {
        width: 460px;
    }

    .mat-dialog-container {
        overflow: visible;
        padding: 24px 0 0 0 !important;
        height: auto !important;
    }

    .mat-dialog-title {
        border-radius: 4px 4px 0 0;
        padding-left: 10px;
        width: calc(100% - 10px);
        padding-right: 0;
        margin: -24px 0 0 0 !important;
    }

    .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    section {
        padding: 20px;
        background: var(--highlight);
        margin-bottom: 20px;

        form {
            margin-top: 0;

            mat-form-field {
                width: 100%;
                margin: 0 0 20px 0;
            }
        }

        h2 {
            margin: 0 0 5px 0;
            font-size: 16px;
            font-weight: bold;
        }

        *:last-child {
            margin-bottom: 0;
        }

        *:last-child .mat-form-field-wrapper {
            margin-bottom: -17px;
        }
    }

    aside {
        padding: 20px;

        label:not(.mat-form-field-label) {
            margin: 10px 0 0 0;
            font-size: 12px;
            &:nth-of-type(1) {
                margin: 0;
            }
        }

        output {
            color: var(--black) !important;
            font-size: 16px !important;
            margin-bottom: 10px;
        }

        *:last-child {
            margin-bottom: 0;
        }
    }

    *:not(nav) > button:not(.back-btn, .mat-icon-button, .cancel) {
        height: 56px;
        margin-bottom: 10px !important;
        width: calc(100% - 40px);
        padding: 5px;
        text-align: left;
        font-weight: normal;
        font-size: 18px;
        transition: all 0.2s ease-out;
        &.center {
            text-align: center;
        }
        &.two-thirds {
            width: 66.6% !important;
        }
        &[color='gray'] {
            background: var(--grey) !important;
            color: var(--white);
        }
        mat-icon {
            margin: 0 10px;
            transform: scale(1.3) translateY(-1px);
        }
        &:not(.mat-button-disabled):hover {
            transform: scale(0.99);
            box-shadow: none !important;
            opacity: 0.95;
        }
    }

    .alt-link {
        text-decoration: underline;
        color: var(--light);
        text-align: center;
        font-size: 17px;
        position: relative;
        margin: 10px auto;

        &:hover {
            text-decoration: none;
            cursor: pointer;
        }
    }

    label:not(.mat-form-field-label) {
        margin: 10px 20px 10px 20px;
        display: block;
        font-size: 18px;
        line-height: 1.33;
        text-align: left;
        color: var(--primary);
    }

    h2 {
        font-size: 18px;
        font-weight: bold;
        line-height: 1.33;
        text-align: left;
        color: var(--primary);
        margin: 5px;

        margin-left: 15px;
        margin-bottom: 5px !important;
    }
    form {
        margin-bottom: 15px;
        mat-form-field {
            width: calc(100% - 40px);
            margin: 0 20px;
            &.half {
                width: calc(50% - 20px);
            }
        }
    }
    swft-hipaa-verification mat-datepicker-toggle {
        margin-right: -5px !important;
    }
}
