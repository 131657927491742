mat-form-field.mat-form-field {
    display: inline-flex;

    .mat-form-field-subscript-wrapper {
        margin-top: 2px;
    }

    .mat-form-field-wrapper {
        width: 100%;
    }

    &.full {
        width: 100%;
    }

    &.half {
        width: calc(50% - 10px);
    }

    &.third {
        width: calc(33.33% - 13.5px);
    }

    &.two-thirds {
        width: calc(66.66% - 8px);
        &.left {
            margin-right: 20px;
        }
    }

    &.right {
        margin-left: auto;
    }

    &.bounce {
        animation: 0.5s linear 0s 3 bounce;
    }

    &.start {
        align-self: flex-start;
    }

    &.solid {
        &.mat-form-field-appearance-fill .mat-form-field-flex {
            background-color: rgba(var(--white--rgb), 0.8) !important;
        }
        &.mat-form-field-appearance-fill .mat-form-field-flex {
            border-radius: 0;
        }
    }

    &.hide-icon {
        .mat-select-arrow-wrapper {
            opacity: 0 !important;
        }
    }

    &.view-only {
        margin-top: 10px;
        &.mat-form-field-appearance-fill .mat-form-field-flex {
            background-color: rgba(var(--white--rgb), 0.1) !important;

            .mat-input-element:disabled {
                color: var(--black);
            }

            .mat-form-field-label-wrapper {
                top: -15px;

                .mat-form-field-label,
                label {
                    color: var(--primary) !important;
                    font-size: 16px;
                    opacity: 0.75;
                }
            }
        }
        &.solid {
            &.mat-form-field-appearance-fill .mat-form-field-flex {
                background-color: rgba(var(--white--rgb), 0.35) !important;
            }
        }
    }

    &.glow {
        &.mat-form-field-appearance-fill .mat-form-field-flex {
            border-radius: 0;
            animation: 0.5s linear 0s infinite glow;
        }
        .mat-form-field-underline {
            display: none;
        }
    }

    &.success {
        .mat-form-field-flex {
            box-shadow: inset 0 0 0 3px var(--accent);
        }
        .mat-form-field-underline {
            display: none;
        }
    }
}

.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: rgba(var(--grey--rgb), 0.1) !important;
}

.mat-form-field-type-mat-chip-list .mat-form-field-flex {
    padding: 10px 10px 5px 10px !important;

    .mat-form-field-infix {
        border-top: 5px;
    }
}

mat-hint {
    font-size: 14px;
    color: var(--grey) !important;
    padding: 5px 0;
    margin-left: -10px;
    word-break: break-all;
    white-space: nowrap;
}

form {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 20px 0 0 0;
    overflow: hidden;

    p {
        &.warn {
            background: rgba(var(--caution--rgb), 0.25);
            padding: 2px 4px;
            border: solid 1px var(--caution);
            &::before {
                content: '⚠';
                color: var(--caution);
                font-size: 20px;
                margin: 0 4px;
                vertical-align: bottom;
            }
        }
    }

    header {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: white;
        background: var(--grey);
        width: 100%;
        padding: 2px 15px;
        box-shadow: 24px 0 0 0 var(--grey), -24px 0 0 0 var(--grey);
        margin-bottom: 24px;

        h3 {
            margin: 0;
            padding: 0;
            position: relative;
            top: 10px;
        }

        nav {
            color: var(--primary);
            padding: 5px 0;

            button {
                margin-left: 10px !important;
            }
        }
    }

    fieldset {
        border: solid 2px var(--grey);
        width: calc(100% - 54px);
        padding: 0 24px;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        margin-bottom: 20px;
        background: #f5f5f5;

        & > h3,
        mat-card h3 {
            width: 100%;
            display: block;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px !important;
            letter-spacing: 2px !important;
            background: var(--grey);
            color: white;
            padding: 2px 10px;
            border-radius: 3px 3px 0 0;
        }

        mat-card h3 {
            width: calc(100% - 20px);
        }

        header {
            padding: 2px 0;
            text-transform: uppercase;

            h3 {
                letter-spacing: 2px !important;
            }

            nav {
                margin-right: -22px;
                transform: scale(0.8);
            }
        }

        &.fieldset-borderless {
            background: none;
            border: none;
            width: inherit;
            padding: 0;
            margin: 15px 0 5px;

            .mat-form-field {
                margin-bottom: 10px;

                &.ng-valid {
                    .mat-form-field-underline {
                        background-color: var(--accent);
                    }
                }

                &.ng-invalid {
                    .mat-hint {
                        color: var(--warn);
                    }
                }

                .mat-form-field-wrapper {
                    height: fit-content;
                }

                .mat-form-field-infix {
                    input.mat-input-element {
                        width: 97%;
                    }

                    .mat-icon-button {
                        width: 15px;
                        height: 15px;
                        line-height: 15px;
                        border-radius: 0%;

                        .mat-icon {
                            line-height: 14px;
                            height: 14px;
                            width: 14px;
                            font-size: 14px;
                        }
                    }
                }

                &.view-only {
                    max-width: 120px;

                    .mat-form-field-infix {
                        width: 120px;
                    }

                    .mat-form-field-ripple,
                    .mat-form-field-underline {
                        height: 0;
                    }
                }
            }

            .mat-form-field.view-only,
            .mat-form-field:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.view-only {
        mat-form-field.mat-form-field {
            margin-top: 10px;
            .mat-form-field-underline {
                display: none;
            }
            &.mat-form-field-appearance-fill .mat-form-field-flex {
                background-color: rgba(var(--white--rgb), 0.1) !important;

                .mat-input-element:disabled {
                    color: var(--black);
                }

                .mat-form-field-label-wrapper {
                    top: -15px;

                    label {
                        color: var(--primary);
                        font-size: 16px;
                        opacity: 0.75;
                    }
                }
            }
            &.solid {
                &.mat-form-field-appearance-fill .mat-form-field-flex {
                    background-color: rgba(var(--white--rgb), 0.35) !important;
                }
            }
        }
        .mat-form-field-appearance-fill .mat-form-field-flex {
            background-color: rgba(var(--grey--rgb), 0.05) !important;
            pointer-events: none;

            .mat-input-element:disabled {
                color: var(--black);
            }

            .mat-form-field-label-wrapper {
                top: -15px;

                label {
                    color: var(--primary);
                    font-size: 16px;
                    opacity: 0.75;
                }
            }
        }

        [cdkdraghandle] {
            display: none;
        }

        .mat-select-disabled .mat-select-value {
            color: var(--black);
        }

        .mat-select-arrow-wrapper {
            opacity: 0;
        }

        fieldset {
            border: none;
            background: none;
            width: 100%;
            padding: 0;

            header {
                background: none;
                box-shadow: none;

                h3 {
                    color: var(--primary);
                    margin: 0;
                    border-bottom: solid 1px var(--primary);
                    width: 100%;
                }

                nav {
                    display: none;
                }
            }
        }
    }
}

.builder-nav-element-drag-preview {
    margin: 20px;
    padding: 7px 10px;
    border: solid 2px var(--grey);
    font-size: 100%;
    color: var(--grey);
    background: var(--lt-grey);
    width: 300px;
    left: -160px !important;
    top: -20px !important;

    &:hover {
        border: solid 2px var(--primary);
        color: var(--primary);
        opacity: 1;
        background: var(--highlight);
        cursor: move;
    }

    header {
        font-size: 115%;
        font-weight: 500;
        margin-bottom: 5px;
    }
}

.builder-element-drag-preview {
    display: block;
    box-shadow: none;
    width: calc(100vw - 690px);
    top: -20px !important;
    left: calc(-100vw + 705px) !important;
    border-radius: 4px;
    background: var(--highlight);
    height: 30px;
    box-shadow: 0.7px 0.7px 5px 0.1px rgb(0 0 0 / 30%);

    h4 {
        display: inline-block;
        width: calc(100% - 213px);
        font-weight: 500 !important;
        color: var(--primary);
        font-size: 123% !important;
        margin: 3px 17px !important;
    }
}

.builder-page-drag-preview {
    overflow: hidden;
    background: var(--primary);
    border-bottom: solid 2px var(--accent);
    padding: 3px 20px;
    width: calc(100vw - 670px);
    margin: 0 -16px 20px -20px !important;
    box-shadow: none;
    display: block;
    word-break: break-all;
    word-wrap: break-word;
    top: -20px !important;
    left: calc(-100vw + 670px) !important;
    border-radius: 5px 5px 0 0;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

    & > * {
        display: inline-block;
    }

    h2 {
        position: relative;
        top: 2px;
        font-weight: normal !important;
        font-size: 190% !important;
        border: none;
        color: var(--white);
        width: auto;
        max-width: calc(100% - 140px);
        margin: 0 !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .form-page-number {
        top: -5px;
    }
}

.builder-page-drag-placeholder,
.builder-element-drag-placeholder {
    overflow: hidden;
    background: var(--grey);
    width: calc(100vw - 720px);
    height: 24px;
    opacity: 0.5;
    margin: 0 -16px 20px 25px !important;
    display: block;
    word-break: break-all;
    word-wrap: break-word;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.builder-element-drag-placeholder {
    background: var(--highlight);
    width: calc(100vw - 820px);
    height: 12px;
    margin: 0 -16px 20px 50px !important;
}

@keyframes bounce {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 1;
        transform: scale(0.95);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes glow {
    0% {
        box-shadow: inset 0 0 0 2px var(--accent), 0 0 0 4px var(--accent);
    }
    50% {
        box-shadow: inset 0 0 0 4px var(--accent), 0 0 0 2px var(--accent);
    }
    100% {
        box-shadow: inset 0 0 0 2px var(--accent), 0 0 0 4px var(--accent);
    }
}

.mat-tab-label-active {
    .mat-tab-label-content {
        font-weight: bolder;
        color: var(--primary);
    }
}

mat-form-field:not(.login-form-field) .mat-form-field-label-wrapper {
    top: -1.2em !important;

    label {
        color: var(--primary);
        font-size: 14px;
        font-weight: 500;
    }
}

mat-form-field[theme='search-dropdown'] {
    transform: translateY(5px);
    margin: 0 10px;
    .mat-select-arrow {
        color: var(--white) !important;
    }
    .mat-form-field-label {
        color: var(--white) !important;
    }
    .mat-form-field-underline {
        background-color: transparent !important;
    }
    .mat-select-placeholder {
        color: rgba(var(--white--rgb), 0.42);
    }
    .mat-select-value {
        color: rgba(var(--white--rgb), 0.87);
    }
}
