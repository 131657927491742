.swft-tooltip {
    position: fixed;
    background: var(--highlight);
    border: solid 1px var(--grey);
    padding: 5px 7px;
    color: var(--primary);
    font-weight: bold;
    margin: 10px 0 0 20px;
    z-index: 999;

    p {
        display: block;
        margin-bottom: 5px;
        text-transform: capitalize;

        &:last-child {
            margin-bottom: 0;
        }

        span {
            margin-left: 5px;
            font-weight: normal;
            color: var(--grey);
        }
    }
}

[swftTooltip] {
    cursor: help;
    color: var(--light);

    &:hover {
        opacity: 0.75;
    }
}
