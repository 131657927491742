mat-radio-group,
.mat-checkbox-group {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;

    mat-radio-button,
    mat-checkbox {
        width: calc(25% - 30px);
        margin: 0 20px 20px 0;
        border: solid 4px var(--grey);
        transition: all 0.3s ease-out;
        background: var(--lt-grey);
        border-radius: 2px;
        min-width: 140px;
        overflow: hidden;

        .mat-checkbox-inner-container {
            margin: 0;
        }

        &.mat-radio-checked,
        &.mat-checkbox-checked {
            border: solid 4px var(--primary);
            background: var(--highlight);

            .mat-radio-container,
            .mat-checkbox-inner-container {
                background: var(--primary);
                box-shadow: 0 2px 0 0 var(--primary);
            }

            label .mat-radio-label-content,
            label .mat-checkbox-label {
                color: var(--primary);
                transform: scale(1.1) translate(2.5%);
            }

            .mat-checkbox-checkmark-path {
                stroke: var(--primary) !important;
            }

            .mat-checkbox-background {
                background: var(--white) !important;
            }
        }

        label .mat-radio-label-content,
        label .mat-checkbox-label {
            padding-left: 20px;
            font-size: 120%;
            font-weight: bold;
            color: var(--grey);
            transition: all 0.3s ease-out;
            width: calc(100% - 80px);
            overflow: hidden;
            text-overflow: ellipsis;
        }

        label.mat-checkbox-layout {
            align-items: center !important;
            width: 100%;
        }

        .mat-radio-container,
        .mat-checkbox-inner-container {
            width: 60px;
            height: 60px;
            background: var(--grey);
            box-shadow: 0 2px 0 0 var(--grey);
            transition: all 0.3s ease-out;

            * {
                border-radius: 50%;
            }

            .mat-radio-outer-circle,
            .mat-checkbox-frame {
                background: var(--white);
                border-color: transparent;
            }

            & > span:not(.mat-ripple) {
                margin: 20px;
            }
        }
    }
}
