snack-bar-container.mat-snack-bar-container {
    border: solid 2px var(--accent);

    button {
        color: var(--white);
    }

    &.snackbar-error {
        border: solid 2px var(--warn);

        button {
            background: var(--warn);
        }
    }

    &.snackbar-caution {
        border: solid 2px var(--caution);

        button {
            background: var(--caution);
        }
    }

    &.snackbar-info {
        border: solid 2px var(--light);

        button {
            background: var(--light);
        }
    }

    simple-snack-bar {
        font-size: 18px;

        button {
            text-transform: uppercase;
        }
    }
}
