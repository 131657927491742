mat-card {
    width: 100%;
    padding: 0 20px 20px 20px !important;
    margin-bottom: 20px;
    &[cdkdrag] {
        .draggable[cdkdraghandle] {
            position: absolute;
            top: calc(50% - 13px);
            right: -12px;
            background: var(--lt-grey);
            color: var(--grey);
            border-radius: 3px;
            padding: 2px 0;
            z-index: 9999;
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
            cursor: grab;
        }
    }
    &.no-header {
        padding: 15px 20px !important;
    }
    & > header:nth-of-type(1) {
        margin: -20px 0 0 -20px;
        width: calc(100% + 10px);
        box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.3);
        background-color: var(--primary);
        border-bottom: 2px solid var(--accent);
        color: var(--white);
        font-size: 187%;
        font-weight: bold;
        letter-spacing: 1px;
        padding: 15px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        text-transform: capitalize;
        mat-icon.expand {
            float: right;
            &.expanded {
                transform: rotate(180deg);
            }
        }
    }
}
